import React from "react"
import Layout from "../../components/App/Layout"
import Navbar from "../../components/App/Navbar"
import Footer from "../../components/App/Footer"
import Overview from "../../components/ProductDetails/netversys/Overview"
import Seo from "../../components/App/Seo"

const NetversysHomepage = () => {
  return (
    <Layout>
      <Seo title={"NETVERSYS - Cloud-ready Versandsoftware mit vielen Carriern"}
           description={"NETVERSYS ist die innovative Versandsoftware für die Bereiche E-Commerce, Retail und Wholesale. Mit vielen Carriern und sinnvollen Funktionen."}
           image={"/images/og/netversys-main-og.png"}>
        <script type="application/ld+json">{`
                {
                  "@context": "https://schema.org",
                  "@type": "BreadcrumbList",
                  "itemListElement": [{
                    "@type": "ListItem",
                    "position": 1,
                    "name": "Home",
                    "item": "https://logentis.de"
                  },{
                    "@type": "ListItem",
                    "position": 2,
                    "name": "Versandsoftware",
                    "item": "https://logentis.de/versandsoftware/"
                  }]
                }
                `}
        </script>
      </Seo>
      <Navbar />
      <Overview />
      <Footer />
    </Layout>
  )
}

export default NetversysHomepage
